import { createGlobalStyle } from "styled-components"

export const theme = {
  font: {
    sans: "Karla, Source Sans Pro, Roboto, sans-serif",
    serif: "Arvo, sans",
    headings: "Fjalla One, sans-serif",
    monospace: '"Ubuntu Mono", monospace',
  },
  colours: {
    common: {
      black: "#000000",
      white: "#FFFFFF",
      grey: "#EEEEEE",
    },
    primary: {
      light: "#ca3b32",
      main: "#c63127",
      dark: "#8a221b",
      dark2: "#1c1c1c",
      contrastText: "#ffffff",
    },
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.5s ease",
    background: "background-color 0.2s ease-in-out",
    backgroundimage: "background 2.0s ease-in-out",
    nav: "top .15s ease-in-out",
  },
  shadows: { 
    shadow1: "0px 5px 20px rgba(30,30,31, 0.05)",
    shadow2: "0 .5rem 1rem rgba(0,0,0,.15)",
    background: "rgb(29 9 34 / 0.7)",
    backgroundfull: "rgb(29 9 34 / 1.0)",
  },
}

export const GlobalStyle = createGlobalStyle`

  //global resets
  
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.sans};
    font-size: 16px;
    color: '#1a202c';
  }
  body {
    line-height: 1.2;
    letter-spacing: 0;
    font-size: 1.4em;
    font-weight: 200;
    font-family: ${({ theme }) => theme.font.sans};
    background-color: ${({ theme }) => theme.colours.common.grey};
    color: ${({ theme }) => theme.colours.text};
  }
  p {
    line-height: 1.2;
  }
  h2, h3 {
    font-family: ${({ theme }) => theme.font.headings};
    font-weight: 400;
    text-transform: uppercase;
  }

  h4 {
    font-style: italic;
    font-weight: 100;
    font-family: roboto;
    margin-bottom: 1em;
  }

  //navigation

  .navbar-collapse {
    justify-content: flex-end;
  }

  .navContainer {
    min-height: 132px;
  }

  .navbar {
    width: 100%;
    padding: 0px;
    min-height: 138px;

    //navscroll
    background-color: ${({ theme }) => theme.colours.common.white} !important;
    box-shadow: ${({ theme }) => theme.shadows.shadow2};
    border-bottom: solid ${({ theme }) => theme.colours.primary.main} 6px;
  }

  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28198, 38, 49, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
    
  
  .navbar-light .navbar-toggler {
    border-color: rgba(198,49,39);
  }

  .navbar-light .navbar-brand{
    margin-left: 1vw;
    flex: auto;
  }

  .navbar-light .navbar-nav {
    text-transform: uppercase;
    margin-right: 0;
  }

  .navbar-light .navbar-nav .nav-link, .navPhone {
    font-weight: 400;
    font-family: ${({ theme }) => theme.font.headings};
    color: ${({ theme }) => theme.colours.primary.main};
    transition: ${({ theme }) => theme.animations.link};
    text-align: center;
  }
  
  .navPhone {
    color: ${({ theme }) => theme.colours.primary.main};
    margin-right: 10px;
  }
  .navPhone:hover {
    color: ${({ theme }) => theme.colours.primary.main};
  }

  a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0,0,0,.7)
  }

  // nav.navScroll {
  //   background-color: ${({ theme }) => theme.colours.common.white} !important;
  //   box-shadow: ${({ theme }) => theme.shadows.shadow2};
  //   border-bottom: solid ${({ theme }) => theme.colours.primary.main} 6px;
  // }

  .navbar-light .navbar-nav .item {
    padding: 0 5px;
  }

  //masthead

  .heroContainer {
    margin: 0 auto;
  }
  .heroContainer, .hero {
    max-height: 70vh;
    // min-height: 70vh;
  }
  .heroContainer, .hero img {
    z-index: -1;
  }
  .hero, .navContainer {
    margin: 0 auto;
  }

  .aboutBg {
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colours.common.white} !important;
    z-index: 100;
    padding: 20px 20px 50px 20px;
    box-shadow: ${({ theme }) => theme.shadows.shadow1};
    min-height: 670px;
  }

  b, strong {
    font-weight: bold;
  }

  //contact

  .form-group {
    margin: 15px 0px;
  }

  .custom-select {
    margin-left: 10px;
  }

  //lightbox

  .SRLAutoplayButton, .SRLThumbnailsButton, .SRLDownloadButton, .SRLExpandButton {
    display: none !important;
  }

//gallery

.card {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

// timeline

.timeline {
  width: 100%
}

.timeline.timeline-one .timeline-item {
  position: relative;
  width: 50%;
  margin-left: 50%;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  text-align: left
}

@media (max-width:575.98px) {
  .timeline.timeline-one .timeline-item {
      width: 100%;
      margin-left: 0
  }
}

.timeline.timeline-one .timeline-item img {
  // margin-bottom: 1.25rem
}

.timeline.timeline-one .timeline-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: .425rem;
  margin-left: -.5rem;
  border: .0625rem solid #dce6f3;
  border-radius: 50%
}

.timeline.timeline-one .timeline-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: -.1875rem;
  width: .0625rem;
  margin-top: 1.875rem;
  margin-left: -.0625rem;
  background-color: #dce6f3
}

.timeline.timeline-one .timeline-item:nth-child(2n+1) {
  margin-left: 0;
  padding-right: 1.5rem;
  padding-left: 0;
  text-align: right
}

@media (max-width:575.98px) {
  .timeline.timeline-one .timeline-item:nth-child(2n+1) {
      padding-right: 0;
      padding-left: 1.5rem;
      text-align: left
  }
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):after,
.timeline.timeline-one .timeline-item:nth-child(2n+1):before {
  right: 0;
  left: auto
}

@media (max-width:575.98px) {

  .timeline.timeline-one .timeline-item:nth-child(2n+1):after,
  .timeline.timeline-one .timeline-item:nth-child(2n+1):before {
      right: auto;
      left: 0
  }
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):before {
  margin-right: -.5rem
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):after {
  margin-right: -.0625rem
}

.timeline.timeline-one.dark .timeline-item:before {
  border: .0625rem solid #b7c3d2
}

.timeline.timeline-one.dark .timeline-item:after {
  background-color: #b7c3d2
}


//form

form, iframe {
  padding: 20px;
}

.grecaptcha-logo iframe {
  padding: 0px;
}


  //SM
  @media (min-width: 576px) { 
    .aboutBg {
      // margin-top: -100px;
      margin-top: 20px;
      border-top: solid 5px ${({ theme }) => theme.colours.primary.main};
      min-height: 350px;
    }
  }

  //MD
  @media (min-width: 768px) { 
    .aboutBg {
      min-height: 300px;
    }

    & .serviceImg {
      padding-right: 0!important;
      padding-left: 0!important;
    }

    .gviFCz {
      color: #FFFFFF !important;
      background-color: #c63127 !important;
      border-color: #ba2117 !important;
      font-family: Fjalla One,sans-serif !important;
      text-transform: uppercase !important;
      padding: 5px 20px !important;
  }

  /*!sc*/
  .gviFCz:hover,.gviFCz:active,.gviFCz .active,.gviFCz .focus,.gviFCz:focus,.gviFCz:not(:disabled):not(.disabled).active,.gviFCz:not(:disabled):not(.disabled):active,.gviFCz .show>.btn-primary.dropdown-toggle {
      color: #FFFFFF !important;
      background-color: #000000 !important;
      border-color: #000000 !important;
      font-family: Fjalla One,sans-serif !important;
      text-transform: uppercase !important;
      padding: 5px 20px !important;
  }

  /*!sc*/
  .gviFCz:focus,.gviFCz:focus {
      box-shadow: 0 0 0 0.2rem #ca3b32 !important;
  }
  
  }
  
  //LG
  @media (min-width: 992px) { 
    .aboutBg {
      min-height: 500px;
    }
  
  }

  //XL
  @media (min-width: 1200px) { 
    .aboutBg {
      min-height: 460px;
    }

  }
  //XXL
  @media (min-width: 1400px) { 

  }
 
`